import React, { useState, useEffect } from 'react';
import './App.css';
import moxImage from './mox.png'; // Import the image
import musicFile from './mox-song.MP3'; // Import the first music file
import formulaStapn from './formulaStapn.png'; // Import the image
import superMax from './super-max.MP3'; // Import the second music file
import telegram from './telegram.png'; // Import the image
import twitter from './twitter.png'; // Import the image
import wideImage from './DEXTools_ black.png'; // Import the wide image

function App() {
    const [audioPlayer1, setAudioPlayer1] = useState(null);
    const [audioPlayer2, setAudioPlayer2] = useState(null);
    const [isMuted, setIsMuted] = useState(false);
    const [isPlaying1, setIsPlaying1] = useState(false);
    const [isPlaying2, setIsPlaying2] = useState(false);

    useEffect(() => {
        if (audioPlayer1 && !isMuted && isPlaying1) {
            audioPlayer1.play().catch(error => {
                console.error('Autoplay prevented. Please interact with the document to play music.');
            });
        } else if (audioPlayer1 && !isPlaying1) {
            audioPlayer1.pause();
        }
    }, [audioPlayer1, isMuted, isPlaying1]);

    useEffect(() => {
        if (audioPlayer2 && !isMuted && isPlaying2) {
            audioPlayer2.play().catch(error => {
                console.error('Autoplay prevented. Please interact with the document to play music.');
            });
            setIsPlaying1(false); // Pause the first song when the second one starts playing
        } else if (audioPlayer2 && !isPlaying2) {
            audioPlayer2.pause();
        }
    }, [audioPlayer2, isMuted, isPlaying2, setIsPlaying1]);

    const handleImageClick1 = () => {
        setIsPlaying1(true);
    };

    const handleImageClick2 = () => {
        setIsPlaying2(true);
    };

    return (
        <div className="App">
            <header>
                <h1>MOX VERSTOOPEN</h1>
            </header>
            <main>
                <p>THIS PAGE IS NO LONGER ACTIVE, USE THE LINKS BELOW, COMMUNITY TAKEOVER</p>
                https://linktr.ee/moxverstoopen
                <img className="mainImage" src={moxImage} alt="Description of the image" onClick={handleImageClick1}/>
                <div className="additionalContent">
                    <p>COMMUNITY TAKEOVER</p>
                    <p>https://linktr.ee/moxverstoopen</p>
                </div>
                <p>CLICK ON THE BEST FORMULA IF YOU LOVE MOX</p>
                <img className="mainImage" src={formulaStapn} alt="Description of the image"
                     onClick={handleImageClick2}/>
                <div className="socialIcons">
                    <a href="https://twitter.com/moxverstoopen" target="_blank" rel="noopener noreferrer">
                        <img className="socialIcon" src={twitter} alt="Twitter"/>
                    </a>
                    <a href="https://t.me/moxverstoopen" target="_blank" rel="noopener noreferrer">
                        <img className="socialIcon" src={telegram} alt="Telegram"/>
                    </a>
                </div>
                <div className="socialIcons">
                    <a href="https://www.dextools.io/app/en/solana/pair-explorer/4b79N48HgyaEucKPcbvUj2rRXsCFJSxYxooqHSq1q9dR?t=1711044511159" target="_blank" rel="noopener noreferrer">
                        <img className="wideImage" src={wideImage} alt="Description of the wide image"/>
                    </a>
                </div>
                <audio
                    ref={audio => setAudioPlayer1(audio)}
                    id="audioPlayer1"
                    controls
                    loop
                    muted={isMuted}
                >
                    <source src={musicFile} type="audio/mp3"/>
                    Your browser does not support the audio element.
                </audio>
                <audio
                    ref={audio => setAudioPlayer2(audio)}
                    id="audioPlayer2"
                    controls
                    loop
                    muted={isMuted}
                >
                    <source src={superMax} type="audio/mp3"/>
                    Your browser does not support the audio element.
                </audio>
            </main>
            <footer>
                <p>&copy; $MOX has no association with Max Verstappen. This token is simply a meme</p>
            </footer>
        </div>
    );
}

export default App;
